import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const praticheService = {
  fastSearchPratiche,
  browse,
  getPraticaById,
  trackPraticaAereo,
  trackPraticaMare,
  trackPraticaMareLite,
  getAllEquipmentsFromTracking,
  getTransportOrdersForPratica,
  trackTransportOrder,
  updateStatus,
  getPraticheByStatusAndSaleChannel,
  getCountByTypeInInterval,
  getEmissions,
  getEmissionsReportPDF
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/pratica`

function fastSearchPratiche(field, value) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl +
      `/fast-search?hint=${value}&search-type=${field}&offset=${0}&limit=${50}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function browse(offset, limit, filter, sortingList) {
  if (!sortingList.length) {
    sortingList = []
    sortingList.push({
      column: 'documentDate',
      direction: 'DESC',
    })
  }
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      offset: offset,
      limit: limit,
      filter: filter,
      sortingList: sortingList,
    }),
  }
  return fetch(resourceUrl + `/browse`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getPraticaById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function trackPraticaAereo(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/track/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

/*function trackPraticaMare(trackingParam, mainTransportCompanyId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/sea-track/${trackingParam}/${mainTransportCompanyId}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}*/

function trackPraticaMare(praticaId, mainTransportCompanyId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/sea-tracking/${praticaId}/${mainTransportCompanyId}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function trackPraticaMareLite(trackingParam, mainTransportCompanyId, documentNbr) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/sea-track-lite?subject=${trackingParam}&company=${mainTransportCompanyId}&documentNbr=${documentNbr}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function getAllEquipmentsFromTracking(praticaId, mainTransportCompanyId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/sea-track-equipments/${praticaId}/${mainTransportCompanyId}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function getTransportOrdersForPratica(documentNbr, tenantId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/transport-orders/${documentNbr}/${tenantId}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function trackTransportOrder(offset, limit, filter, sortingList) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      offset: offset,
      limit: limit,
      filter: filter,
      sortingList: sortingList,
    }),
  }
  return fetch(resourceUrl + `/transport-orders/track`, requestOptions).then(commons.handleJsonResponse)
}

function updateStatus(praticaId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/update-status/${praticaId}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function getPraticheByStatusAndSaleChannel(status, saleChannel) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/stato-pratica?status=${status}&saleChannel=${saleChannel}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function getCountByTypeInInterval(saleChannel) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    resourceUrl + `/creation-interval?saleChannel=${saleChannel}`,
    requestOptions,
  ).then(commons.handleJsonResponse)
}

function getEmissions(praticaId, emissionType) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
      resourceUrl + `/emissions/${praticaId}/${emissionType}`,
      requestOptions,
  ).then(commons.handleJsonResponse)
}

function getEmissionsReportPDF(praticaId, emissionType) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
      resourceUrl + `/emissions/pdf/${praticaId}/${emissionType}`,
      requestOptions,
  ).then(commons.handleStringResponse)
}


