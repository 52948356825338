<template>
  <v-container fill-height fluid class="d-table-fixed pa-0">
    <div class="d-table-header-group">
      <v-card class="mb-2">
        <v-row class="ma-0 py-1 pl-1">
          <v-col cols="auto" class="pa-0">
            <v-btn
              icon
              color="primary"
              @click="$router.push({name: 'dashboard', params: { reload: search ? false : true } })">
              <v-icon size="30">mdi-arrow-left-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col class="pa-0 pl-1" style="padding-top: 0.1rem !important;">
            <v-row class="ma-0" dense>
              <v-col class="d-flex align-center pa-0">
                <span class="text-h5 font-weight-black">
                  {{ pratica.documentNbr }}
                </span>
              </v-col>
              <v-col class="d-flex align-center pa-0">
                <span class="text-h5 primary--text font-weight-black">
                  {{ pratica.shipper ? pratica.shipper.customerName : null }}
                </span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="d-flex align-center pa-0">
                <span class="text-overline">
                  {{ $t('label.createDate') }}
                </span>
                <span class="font-weight-bold mx-2">
                  {{ formatDate(pratica.documentDate) }}
                </span>
              </v-col>
              <v-col class="d-flex align-center pa-0">
                <v-row class="ma-0" dense>
                  <v-col cols="auto" class="pa-0 mr-5">
                    <span class="text-overline">
                      {{ $t('label.commoditySector') }}
                    </span>
                    <span class="font-weight-bold">
                      {{ pratica.commoditySector }}
                    </span>
                  </v-col>
                  <v-col cols="auto" class="pa-0">
                    <span class="text-overline">
                      {{ $t('label.saleChannel') }}
                    </span>
                    <!-- <span class="font-weight-bold mx-2">
                        {{ pratica.saleChannel }}
                      </span> -->
                    <v-icon
                      class="primary--text"
                      size="20"
                      v-if="saleChannelDesc(pratica.saleChannel) == 'Nave'"
                    >
                      mdi-ferry
                    </v-icon>
                    <v-icon
                      class="primary--text"
                      size="20"
                      v-if="saleChannelDesc(pratica.saleChannel) == 'Terra'"
                    >
                      mdi-truck
                    </v-icon>
                    <v-icon
                      class="primary--text"
                      size="20"
                      v-if="saleChannelDesc(pratica.saleChannel) == 'Aereo'"
                    >
                      mdi-airplane
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-card
      width="100%"
      v-if="internalVisibilityAuth == true"
      class="d-table-fixed fill-height"
    >
      <v-tabs
        v-model="tab"
        class="d-table-header-group"
        background-color="accent"
        height="2rem"
        fixed-tabs
      >
        <v-tab>{{ $t('label.info') }}</v-tab>
        <v-tab>{{ $t('label.documents') }}</v-tab>
        <v-tab>{{ $t('label.roadTransport') }}</v-tab>
        <v-tab>{{ $t('label.mainTransport') }}</v-tab>
        <v-tab>{{ $t('label.customsTransit') }}</v-tab>
        <v-tab>{{ $t('label.foreignCorrespondent') }}</v-tab>
        <v-tab v-if="bookingAuth && showCreateBooking">{{ $t('label.booking') }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="display: table-row !important;">
        <v-tab-item class="pa-2">
          <PraticaInfo
            :pratica="pratica"
            :praticaActions="praticaActions"
            :company="company"
            @update-props="updateActions"
            @open-booking="openBooking"
            @open-shipping-instruction="openShippingInstruction"
          />
        </v-tab-item>
        <v-tab-item class="pa-2">
          <PraticaDocuments
            :pratica="pratica"
          />
        </v-tab-item>
        <v-tab-item class="pa-2">
          <PraticaAutotrasporto
            :pratica="pratica"
          />
        </v-tab-item>
        <v-tab-item class="pa-2">
          <PraticaTrasportoPrincipaleAereo
            v-if="pratica.saleChannel == 40"
            :pratica="pratica"
          />
          <PraticaTrasportoPrincipaleMare
            v-if="pratica.saleChannel == 20"
            :pratica="pratica"
            :imballi="imballi"
            :praticaActions="praticaActions"
            :company="company"
            @update-props="updateActions"
          />
          <PraticaTrasportoPrincipaleMare
              v-if="isMastersped && pratica.saleChannel == 30"
              :pratica="pratica"
              :imballi="imballi"
              :praticaActions="praticaActions"
              :company="company"
              @update-props="updateActions"
          />
        </v-tab-item>
        <v-tab-item class="pa-2">
          <PraticaTransitarioDoganale
            :pratica="pratica"
            :praticaActions="praticaActions"
            @update-props="updateActions"
          />
        </v-tab-item>
        <v-tab-item class="pa-2">
          <PraticaCorrispondenteEstero
            :pratica="pratica"
            :praticaActions="praticaActions"
            @update-props="updateActions"
          />
        </v-tab-item>
        <v-tab-item class="pa-1" v-if="bookingAuth && showCreateBooking">
          <BookingTrial :pratica="pratica" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card width="100%" v-if="internalVisibilityAuth == false">
      <v-tabs v-model="tab" fixed-tabs background-color="accent" height="2rem">
        <v-tab>{{ $t('label.trackingInformation') }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item class="pa-0">
          <PraticaExternalTracking :praticaId="id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { dateFilter } from '@/filters/dateFilter'
import { praticheService } from '@/services/pratiche.service.js'
import { imballiService } from '@/services/imballi.service.js'
import { praticaActionsService } from '@/services/praticaActions.service.js'
import { suppliersService } from '@/services/suppliers.service.js'
import PraticaInfo from '@/components/elements/pratica/PraticaInfo.vue'
import PraticaDocuments from '@/components/elements/pratica/PraticaDocuments.vue'
import PraticaAutotrasporto from '@/components/elements/pratica/PraticaAutotrasporto.vue'
import PraticaTrasportoPrincipaleAereo from '@/components/elements/pratica/PraticaTrasportoPrincipaleAereo.vue'
import PraticaTrasportoPrincipaleMare from '@/components/elements/pratica/PraticaTrasportoPrincipaleMare.vue'
import PraticaTransitarioDoganale from '@/components/elements/pratica/PraticaTransitarioDoganale.vue'
import PraticaCorrispondenteEstero from '@/components/elements/pratica/PraticaCorrispondenteEstero.vue'
import BookingTrial from '@/components/views/BookingTrial.vue'
import PraticaExternalTracking from '@/components/elements/pratica/PraticaExternalTracking.vue'

export default {
  name: 'PraticaPage',
  props: ['id'],
  components: {
    PraticaInfo,
    PraticaDocuments,
    PraticaAutotrasporto,
    PraticaTrasportoPrincipaleAereo,
    PraticaTrasportoPrincipaleMare,
    PraticaTransitarioDoganale,
    PraticaCorrispondenteEstero,
    BookingTrial,
    PraticaExternalTracking,
  },
  data() {
    return {
      tab: null,

      pratica: {},
      imballi: [],
      company: {},
      praticaActions: {},

      message: '',
      snackbar: false,
      isSuccess: true,
    }
  },
  computed: {
    ...mapState('account', ['userAuthorizations', 'internalVisibilityAuth']),
    ...mapState('searchbar', ['search']),
    showCorrispEstero() {
      if (!this.pratica) return
      return (
        this.pratica.incoterms == 'FCA' ||
        this.pratica.incoterms == 'FAS' ||
        this.pratica.incoterms == 'FOB'
      )
    },
    bookingAuth() {
      return this.userAuthorizations.includes('AUTH_BOOKING')
    },
    showCreateBooking(){
      return this.company.supplierName &&
      (this.company.supplierName.includes('CMA') && this.company.supplierName.includes('CGM'))
    },
    isMastersped() {
      return process.env.VUE_APP_IS_MSPED_GSCV === 'true'
    }
  },
  methods: {
    init() {
      this.loadPratica()
    },
    loadPratica() {
      praticheService.getPraticaById(this.id).then((resp) => {
        if (!resp) return
        this.pratica = resp
        this.loadPraticaActions()
        this.loadImballiForPratica()
        this.getCompany()
      })
    },
    loadImballiForPratica() {
      imballiService
        .getImballiByDocumentNbr(this.pratica.documentNbr, this.pratica.tenantId)
        .then((resp) => {
          if (resp) this.imballi = resp
        })
    },
    loadPraticaActions() {
      praticaActionsService
        .getActionsForPratica(this.pratica.id)
        .then((resp) => {
          if (resp) this.praticaActions = resp
        })
    },
    getCompany() {
      if (
        !this.pratica.mainTransportCompany ||
        !this.pratica.mainTransportCompany.length
      )
        return
      suppliersService
        .getSupplierBySupplierId(this.pratica.mainTransportCompany, this.pratica.tenantId)
        .then((resp) => {
          if (resp) this.company = resp
        })
    },
    updateActions(actions) {
      praticaActionsService.updateActions(actions.id, actions).then(() => {
        let msg = 'Flag ' + this.$t('label.successUpdate')
        this.setSnackbarMessage({ text: msg, color: 'success' })
        this.loadPraticaActions()
        this.updateStatusPratica()
      })
    },
    updateStatusPratica() {
      praticheService.updateStatus(this.id)
    },
    formatDate(value) {
      return dateFilter.formatDateDay(value)
    },
    saleChannelDesc(saleChannel) {
      let description
      switch (saleChannel) {
        case '20':
          description = 'Nave'
          break
        case '30':
          description = 'Terra'
          break
        case '40':
          description = 'Aereo'
          break
      }
      return description
    },
    openShippingInstruction() {
      this.tab = 5
    },
    openBooking() {
      this.tab = 5
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
.v-tabs-items {
  display: grid !important;
}
.v-tabs-items::v-deep .v-window__container {
  height: 100%;
}
.v-tabs-items::v-deep .v-window-item {
  height: 100%;
}
.v-application .text-overline {
  line-height: 0 !important;
}
/* .col {
  border: solid 1px orange;
} */
</style>
