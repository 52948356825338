var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-flex flex-column" },
    [
      _c(
        "v-row",
        { staticClass: "d-flex flex-row align-center" },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("v-file-input", {
                attrs: { label: "Upload file", accept: ".xlsx", clearable: "" },
                on: { change: _vm.onFileSelected },
                model: {
                  value: _vm.selectedFile,
                  callback: function ($$v) {
                    _vm.selectedFile = $$v
                  },
                  expression: "selectedFile",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.totalRows
        ? _c("span", [_vm._v(_vm._s(`Total Rows: ${_vm.totalRows}`))])
        : _vm._e(),
      _vm.errorRows.size
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { attrs: { left: "", color: "error" } }, [
                    _vm._v("mdi-alert-circle"),
                  ]),
                  _vm._v(
                    " " + _vm._s(`Failed WBS: ${_vm.errorRows.size}`) + " "
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    _vm._l(
                      Array.from(_vm.errorRows),
                      function ([rowId, error], index) {
                        return _c(
                          "v-list-item",
                          { key: index },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _c("strong", [_vm._v(_vm._s(rowId) + ":")]),
                                  _vm._v(" " + _vm._s(error) + " "),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }