import {commons} from "./commons";

export const roadShipmentsService = {
    importRoadShipments,
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/road-shipments`

function importRoadShipments(uploadedExcel) {
    const requestOptions = {
        method: "POST",
        body: uploadedExcel,
    };
    return fetch(resourceUrl + "/import", requestOptions).then(commons.handleJsonResponse);
}
