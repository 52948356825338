var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-block", attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "pa-5" },
        [
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.openDialog } },
            [
              _c("v-icon", [_vm._v("mdi-plus")]),
              _vm._v(" " + _vm._s(_vm.$t("label.newUser")) + " "),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "40%" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(_vm._s(_vm.$t("label.newUser")))]),
                  _c(
                    "v-alert",
                    {
                      attrs: { type: "error", dismissible: "" },
                      model: {
                        value: _vm.errorAlert,
                        callback: function ($$v) {
                          _vm.errorAlert = $$v
                        },
                        expression: "errorAlert",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("label.firstName"),
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.userData.firstName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userData, "firstName", $$v)
                                      },
                                      expression: "userData.firstName",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("label.username"),
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.userData.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userData, "username", $$v)
                                      },
                                      expression: "userData.username",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("label.password"),
                                      type: "password",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.userData.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userData, "password", $$v)
                                      },
                                      expression: "userData.password",
                                    },
                                  }),
                                  _c("v-select", {
                                    staticClass: "pb-5 white--text",
                                    attrs: {
                                      label: _vm.$t("label.groups"),
                                      items: _vm.groups,
                                      "item-text": "groupDes",
                                      "item-value": "id",
                                      "return-object": "",
                                      "hide-details": "",
                                      multiple: "",
                                      flat: "",
                                    },
                                    model: {
                                      value: _vm.userData.groups,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userData, "groups", $$v)
                                      },
                                      expression: "userData.groups",
                                    },
                                  }),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        attach: "",
                                        "close-on-click": "",
                                        "open-on-click": false,
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              !_vm.showSearch["customersCreate"]
                                                ? _c("v-text-field", {
                                                    ref: "customersCreate",
                                                    staticClass: "pb-5",
                                                    attrs: {
                                                      label:
                                                        _vm.$t("label.clients"),
                                                      value:
                                                        _vm.formatSelectedCustomer,
                                                      "item-text":
                                                        "customerName",
                                                      "prepend-inner-icon":
                                                        "mdi-account-arrow-right",
                                                      "append-icon": _vm
                                                        .userData.customers
                                                        .length
                                                        ? "mdi-close"
                                                        : "",
                                                      "hide-details": "",
                                                      readonly: "",
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.userData.customers =
                                                          []
                                                      },
                                                      click: function ($event) {
                                                        _vm.showSearch[
                                                          "customersCreate"
                                                        ] = true
                                                      },
                                                    },
                                                  })
                                                : _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.clients"
                                                            ),
                                                          "prepend-inner-icon":
                                                            "mdi-account-arrow-right",
                                                          "append-icon":
                                                            "mdi-magnify",
                                                          "hide-details": "",
                                                        },
                                                        on: {
                                                          click: [
                                                            function ($event) {
                                                              $event.stopPropagation()
                                                            },
                                                            function ($event) {
                                                              return _vm.handleSearch(
                                                                "customersCreate"
                                                              )
                                                            },
                                                          ],
                                                          "click:append":
                                                            function ($event) {
                                                              return _vm.searchCustomers(
                                                                _vm.search
                                                                  .customersCreate,
                                                                "customersCreate"
                                                              )
                                                            },
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.searchCustomers(
                                                              _vm.search
                                                                .customersCreate,
                                                              "customersCreate"
                                                            )
                                                          },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.resetSearchBox(
                                                              "shipperId"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.search
                                                              .customersCreate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.search,
                                                              "customersCreate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "search.customersCreate",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.menuCustomerCreate,
                                        callback: function ($$v) {
                                          _vm.menuCustomerCreate = $$v
                                        },
                                        expression: "menuCustomerCreate",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "dropdown-menu" },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "custom-table",
                                            attrs: {
                                              headers: [
                                                {
                                                  text: "",
                                                  value: "customerName",
                                                },
                                              ],
                                              items: _vm.customersFilterList,
                                              "items-per-page": 4,
                                              page: _vm.tablePage,
                                              "hide-default-header": "",
                                              "checkbox-color": "primary",
                                              "show-select": "",
                                            },
                                            on: {
                                              "update:page": function ($event) {
                                                _vm.tablePage = $event
                                              },
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.customerName",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "custom-table-td",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.customerName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.userData.customers,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userData,
                                                  "customers",
                                                  $$v
                                                )
                                              },
                                              expression: "userData.customers",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        attach: "",
                                        "close-on-click": "",
                                        "open-on-click": false,
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              !_vm.showSearch[
                                                "foreignAgentsCreate"
                                              ]
                                                ? _c("v-text-field", {
                                                    ref: "foreignAgentsCreate",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "label.foreignAgents"
                                                      ),
                                                      value:
                                                        _vm.formatSelectedForeignAgent,
                                                      "item-text":
                                                        "customerName",
                                                      "prepend-inner-icon":
                                                        "mdi-account-arrow-right",
                                                      "append-icon": _vm
                                                        .userData.foreignAgents
                                                        .length
                                                        ? "mdi-close"
                                                        : "",
                                                      "hide-details": "",
                                                      readonly: "",
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.userData.foreignAgents =
                                                          []
                                                      },
                                                      click: function ($event) {
                                                        _vm.showSearch[
                                                          "foreignAgentsCreate"
                                                        ] = true
                                                      },
                                                    },
                                                  })
                                                : _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "label.foreignAgents"
                                                          ),
                                                          "prepend-inner-icon":
                                                            "mdi-account-arrow-right",
                                                          "append-icon":
                                                            "mdi-magnify",
                                                          "hide-details": "",
                                                        },
                                                        on: {
                                                          click: [
                                                            function ($event) {
                                                              $event.stopPropagation()
                                                            },
                                                            function ($event) {
                                                              return _vm.handleSearch(
                                                                "foreignAgentsCreate"
                                                              )
                                                            },
                                                          ],
                                                          "click:append":
                                                            function ($event) {
                                                              return _vm.searchCustomers(
                                                                _vm.search
                                                                  .foreignAgentsCreate,
                                                                "foreignAgentsCreate"
                                                              )
                                                            },
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.searchCustomers(
                                                              _vm.search
                                                                .foreignAgentsCreate,
                                                              "foreignAgentsCreate"
                                                            )
                                                          },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.resetSearchBox(
                                                              "shipperId"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.search
                                                              .foreignAgentsCreate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.search,
                                                              "foreignAgentsCreate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "search.foreignAgentsCreate",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.menuForeignAgentCreate,
                                        callback: function ($$v) {
                                          _vm.menuForeignAgentCreate = $$v
                                        },
                                        expression: "menuForeignAgentCreate",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "dropdown-menu" },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "custom-table",
                                            attrs: {
                                              headers: [
                                                {
                                                  text: "",
                                                  value: "customerName",
                                                },
                                              ],
                                              items: _vm.customersFilterList,
                                              "items-per-page": 4,
                                              page: _vm.tablePage,
                                              "hide-default-header": "",
                                              "checkbox-color": "primary",
                                              "show-select": "",
                                            },
                                            on: {
                                              "update:page": function ($event) {
                                                _vm.tablePage = $event
                                              },
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.customerName",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "custom-table-td",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.customerName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.userData.foreignAgents,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userData,
                                                  "foreignAgents",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "userData.foreignAgents",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("label.lastName"),
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.userData.lastName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userData, "lastName", $$v)
                                      },
                                      expression: "userData.lastName",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("label.email"),
                                      type: "email",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.userData.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userData, "email", $$v)
                                      },
                                      expression: "userData.email",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("label.repeatPassword"),
                                      type: "password",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.userData.repeatPassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userData,
                                          "repeatPassword",
                                          $$v
                                        )
                                      },
                                      expression: "userData.repeatPassword",
                                    },
                                  }),
                                  _c("v-select", {
                                    staticClass: "pb-5 white--text",
                                    attrs: {
                                      label: "Companies",
                                      items: _vm.companies,
                                      "item-text": "code",
                                      "item-value": "id",
                                      "hide-details": "",
                                      required: "",
                                      flat: "",
                                    },
                                    model: {
                                      value: _vm.userData.circleCompanyId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userData,
                                          "circleCompanyId",
                                          $$v
                                        )
                                      },
                                      expression: "userData.circleCompanyId",
                                    },
                                  }),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        attach: "",
                                        "close-on-click": "",
                                        "open-on-click": false,
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              !_vm.showSearch["shippersCreate"]
                                                ? _c("v-text-field", {
                                                    ref: "shippersCreate",
                                                    staticClass: "pb-5",
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "label.shippers"
                                                        ),
                                                      value:
                                                        _vm.formatSelectedShippers,
                                                      "item-text":
                                                        "customerName",
                                                      "prepend-inner-icon":
                                                        "mdi-account-arrow-right",
                                                      "append-icon": _vm
                                                        .userData.shippers
                                                        .length
                                                        ? "mdi-close"
                                                        : "",
                                                      "hide-details": "",
                                                      readonly: "",
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.userData.shippers =
                                                          []
                                                      },
                                                      click: function ($event) {
                                                        _vm.showSearch[
                                                          "shippersCreate"
                                                        ] = true
                                                      },
                                                    },
                                                  })
                                                : _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.shippers"
                                                            ),
                                                          "prepend-inner-icon":
                                                            "mdi-account-arrow-right",
                                                          "append-icon":
                                                            "mdi-magnify",
                                                          "hide-details": "",
                                                        },
                                                        on: {
                                                          click: [
                                                            function ($event) {
                                                              $event.stopPropagation()
                                                            },
                                                            function ($event) {
                                                              return _vm.handleSearch(
                                                                "shippersCreate"
                                                              )
                                                            },
                                                          ],
                                                          "click:append":
                                                            function ($event) {
                                                              return _vm.searchCustomers(
                                                                _vm.search
                                                                  .shippersCreate,
                                                                "shippersCreate"
                                                              )
                                                            },
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.searchCustomers(
                                                              _vm.search
                                                                .shippersCreate,
                                                              "shippersCreate"
                                                            )
                                                          },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.resetSearchBox(
                                                              "shipperId"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.search
                                                              .shippersCreate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.search,
                                                              "shippersCreate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "search.shippersCreate",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.menuShipperCreate,
                                        callback: function ($$v) {
                                          _vm.menuShipperCreate = $$v
                                        },
                                        expression: "menuShipperCreate",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "dropdown-menu" },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "custom-table",
                                            attrs: {
                                              headers: [
                                                {
                                                  text: "",
                                                  value: "customerName",
                                                },
                                              ],
                                              items: _vm.customersFilterList,
                                              "items-per-page": 4,
                                              page: _vm.tablePage,
                                              "hide-default-header": "",
                                              "checkbox-color": "primary",
                                              "show-select": "",
                                            },
                                            on: {
                                              "update:page": function ($event) {
                                                _vm.tablePage = $event
                                              },
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.customerName",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "custom-table-td",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.customerName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.userData.shippers,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userData,
                                                  "shippers",
                                                  $$v
                                                )
                                              },
                                              expression: "userData.shippers",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        attach: "",
                                        "close-on-click": "",
                                        "open-on-click": false,
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              !_vm.showSearch[
                                                "consigneesCreate"
                                              ]
                                                ? _c("v-text-field", {
                                                    ref: "consigneesCreate",
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "label.consignees"
                                                        ),
                                                      value:
                                                        _vm.formatSelectedConsignee,
                                                      "item-text":
                                                        "customerName",
                                                      "prepend-inner-icon":
                                                        "mdi-account-arrow-right",
                                                      "append-icon": _vm
                                                        .userData.consignees
                                                        .length
                                                        ? "mdi-close"
                                                        : "",
                                                      "hide-details": "",
                                                      readonly: "",
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.userData.consignees =
                                                          []
                                                      },
                                                      click: function ($event) {
                                                        _vm.showSearch[
                                                          "consigneesCreate"
                                                        ] = true
                                                      },
                                                    },
                                                  })
                                                : _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "label.consignees"
                                                            ),
                                                          "prepend-inner-icon":
                                                            "mdi-account-arrow-right",
                                                          "append-icon":
                                                            "mdi-magnify",
                                                          "hide-details": "",
                                                        },
                                                        on: {
                                                          click: [
                                                            function ($event) {
                                                              $event.stopPropagation()
                                                            },
                                                            function ($event) {
                                                              return _vm.handleSearch(
                                                                "consigneesCreate"
                                                              )
                                                            },
                                                          ],
                                                          "click:append":
                                                            function ($event) {
                                                              return _vm.searchCustomers(
                                                                _vm.search
                                                                  .consigneesCreate,
                                                                "consigneesCreate"
                                                              )
                                                            },
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.searchCustomers(
                                                              _vm.search
                                                                .consigneesCreate,
                                                              "consigneesCreate"
                                                            )
                                                          },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.resetSearchBox(
                                                              "shipperId"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.search
                                                              .consigneesCreate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.search,
                                                              "consigneesCreate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "search.consigneesCreate",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.menuConsigneeCreate,
                                        callback: function ($$v) {
                                          _vm.menuConsigneeCreate = $$v
                                        },
                                        expression: "menuConsigneeCreate",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "dropdown-menu" },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "custom-table",
                                            attrs: {
                                              headers: [
                                                {
                                                  text: "",
                                                  value: "customerName",
                                                },
                                              ],
                                              items: _vm.customersFilterList,
                                              "items-per-page": 4,
                                              page: _vm.tablePage,
                                              "hide-default-header": "",
                                              "checkbox-color": "primary",
                                              "show-select": "",
                                            },
                                            on: {
                                              "update:page": function ($event) {
                                                _vm.tablePage = $event
                                              },
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item.customerName",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "custom-table-td",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.customerName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.userData.consignees,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userData,
                                                  "consignees",
                                                  $$v
                                                )
                                              },
                                              expression: "userData.consignees",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.saveUser },
                        },
                        [_vm._v(_vm._s(_vm.$t("label.save")))]
                      ),
                      _c("v-btn", { on: { click: _vm.closeDialog } }, [
                        _vm._v(_vm._s(_vm.$t("label.cancel"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "mb-1",
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm._e(),
      _c(
        "v-expansion-panels",
        {
          staticClass: "align-self-start",
          attrs: { width: "100%" },
          model: {
            value: _vm.panel,
            callback: function ($$v) {
              _vm.panel = $$v
            },
            expression: "panel",
          },
        },
        _vm._l(_vm.users, function (user, i) {
          return _c(
            "v-expansion-panel",
            { key: i },
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass: "px-3 py-0",
                  attrs: { "disable-icon-rotate": "" },
                  scopedSlots: _vm._u(
                    [
                      _vm.panel == i
                        ? {
                            key: "actions",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "red", fab: "", small: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.confirmDelete.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { size: "25", color: "white" } },
                                      [_vm._v(" mdi-delete ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { "max-width": "500px" },
                                    model: {
                                      value: _vm.deleteDialog,
                                      callback: function ($$v) {
                                        _vm.deleteDialog = $$v
                                      },
                                      expression: "deleteDialog",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "headline" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("label.confirmDelete")
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("v-card-text", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "message.confirmDeletion"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.deleteDialog = false
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("label.cancel"))
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "white--text",
                                                attrs: { color: "red" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteUser(
                                                      _vm.currentUser
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("label.delete")
                                                  ) + " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      small: "",
                                      color: "success",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.updateUser(_vm.currentUser)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { size: "25", color: "white" } },
                                      [_vm._v(" mdi-content-save ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c("v-col", { staticClass: "d-flex align-center pa-0" }, [
                        _c("span", [_vm._v(_vm._s(user.lastName))]),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(user.firstName)),
                        ]),
                      ]),
                      _c("v-col", { staticClass: "d-flex align-center pa-0" }, [
                        _c("span", [_vm._v(_vm._s(user.email))]),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        _vm._l(user.groups, function (element, i) {
                          return _c(
                            "v-chip",
                            {
                              key: i,
                              staticClass: "ma-2 pa-2 text-overline",
                              attrs: { color: "primary", small: "" },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "pr-1", attrs: { small: "" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getIcon(element.groupDes)) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm._v(" " + _vm._s(element.groupDes) + " "),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c("v-col", { staticClass: "pa-0 pl-3" }, [
                        _c(
                          "span",
                          { staticClass: "text-overline primary--text" },
                          [_vm._v(" " + _vm._s(_vm.$t("label.settings")) + " ")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mx-2 mt-0 mb-5" }),
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-5" },
                        [
                          _c("v-text-field", {
                            attrs: { label: _vm.$t("label.firstName") },
                            model: {
                              value: _vm.currentUser.firstName,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentUser, "firstName", $$v)
                              },
                              expression: "currentUser.firstName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-5" },
                        [
                          _c("v-text-field", {
                            attrs: { label: _vm.$t("label.lastName") },
                            model: {
                              value: _vm.currentUser.lastName,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentUser, "lastName", $$v)
                              },
                              expression: "currentUser.lastName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-5" },
                        [
                          _c("v-text-field", {
                            attrs: { label: _vm.$t("label.email") },
                            model: {
                              value: _vm.currentUser.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentUser, "email", $$v)
                              },
                              expression: "currentUser.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-5" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("label.password"),
                              type: "password",
                            },
                            model: {
                              value: _vm.currentUser.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentUser, "password", $$v)
                              },
                              expression: "currentUser.password",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "12"
                              : "2",
                          },
                        },
                        [
                          _c("v-select", {
                            staticClass: "no-overflow white--text",
                            attrs: {
                              items: _vm.languageItems,
                              label: _vm.$t("label.language"),
                              "item-text": "text",
                              "return-object": "",
                              "prepend-icon": "mdi-translate",
                              "hide-details": "",
                              dense: "",
                              flat: "",
                            },
                            model: {
                              value: _vm.userParams["language"],
                              callback: function ($$v) {
                                _vm.$set(_vm.userParams, "language", $$v)
                              },
                              expression: "userParams['language']",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "12"
                              : "5",
                          },
                        },
                        [
                          _c("v-select", {
                            staticClass: "no-overflow white--text",
                            attrs: {
                              label: _vm.$t("label.groups"),
                              items: _vm.groups,
                              "item-text": "groupDes",
                              "item-value": "id",
                              "return-object": "",
                              "prepend-icon": "mdi-account-group",
                              "hide-details": "",
                              multiple: "",
                              dense: "",
                              flat: "",
                            },
                            model: {
                              value: _vm.currentUser.groups,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentUser, "groups", $$v)
                              },
                              expression: "currentUser.groups",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "12"
                              : "5",
                          },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                attach: "",
                                "close-on-click": "",
                                "open-on-click": false,
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        !_vm.showSearch["customers"]
                                          ? _c("v-text-field", {
                                              ref: "customers",
                                              refInFor: true,
                                              attrs: {
                                                label:
                                                  _vm.$t("label.customers"),
                                                value: _vm.formatCustomers,
                                                "item-text": "customerName",
                                                "prepend-inner-icon":
                                                  "mdi-account-arrow-right",
                                                "append-icon": user.customers
                                                  .length
                                                  ? "mdi-close"
                                                  : "",
                                                "hide-details": "",
                                                readonly: "",
                                                dense: "",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  _vm.currentUser.customers = []
                                                },
                                                click: function ($event) {
                                                  _vm.showSearch[
                                                    "customers"
                                                  ] = true
                                                },
                                              },
                                            })
                                          : _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("label.customers"),
                                                    "prepend-inner-icon":
                                                      "mdi-account-arrow-right",
                                                    "append-icon":
                                                      "mdi-magnify",
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: [
                                                      function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                      function ($event) {
                                                        return _vm.handleSearch(
                                                          "customers"
                                                        )
                                                      },
                                                    ],
                                                    "click:append": function (
                                                      $event
                                                    ) {
                                                      return _vm.searchCustomers(
                                                        _vm.search.customers,
                                                        "customers"
                                                      )
                                                    },
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.searchCustomers(
                                                        _vm.search.customers,
                                                        "customers"
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.resetSearchBox(
                                                        "shipperId"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.search.customers,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.search,
                                                        "customers",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "search.customers",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.menuCustomer,
                                callback: function ($$v) {
                                  _vm.menuCustomer = $$v
                                },
                                expression: "menuCustomer",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "dropdown-menu" },
                                [
                                  _c("v-data-table", {
                                    staticClass: "custom-table",
                                    attrs: {
                                      headers: [
                                        { text: "", value: "customerName" },
                                      ],
                                      items: _vm.customersFilterList,
                                      "items-per-page": 4,
                                      page: _vm.tablePage,
                                      "hide-default-header": "",
                                      "checkbox-color": "primary",
                                      "show-select": "",
                                      dense: "",
                                    },
                                    on: {
                                      "update:page": function ($event) {
                                        _vm.tablePage = $event
                                      },
                                      "item-selected": (v) =>
                                        _vm.selectItem(
                                          v,
                                          "customers",
                                          "customerId"
                                        ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.customerName",
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "custom-table-td",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.customerName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.currentUser.customers,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentUser,
                                          "customers",
                                          $$v
                                        )
                                      },
                                      expression: "currentUser.customers",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "12"
                              : "4",
                          },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                attach: "",
                                "close-on-click": "",
                                "open-on-click": false,
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        !_vm.showSearch["shippers"]
                                          ? _c("v-text-field", {
                                              ref: "shippers",
                                              refInFor: true,
                                              attrs: {
                                                label: _vm.$t("label.shippers"),
                                                value: _vm.formatShippers,
                                                "item-text": "customerName",
                                                "prepend-inner-icon":
                                                  "mdi-account-arrow-right",
                                                "append-icon": user.shippers
                                                  .length
                                                  ? "mdi-close"
                                                  : "",
                                                "hide-details": "",
                                                readonly: "",
                                                dense: "",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  _vm.currentUser.shippers = []
                                                },
                                                click: function ($event) {
                                                  _vm.showSearch[
                                                    "shippers"
                                                  ] = true
                                                },
                                              },
                                            })
                                          : _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("label.shippers"),
                                                    "prepend-inner-icon":
                                                      "mdi-account-arrow-right",
                                                    "append-icon":
                                                      "mdi-magnify",
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: [
                                                      function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                      function ($event) {
                                                        return _vm.handleSearch(
                                                          "shippers"
                                                        )
                                                      },
                                                    ],
                                                    "click:append": function (
                                                      $event
                                                    ) {
                                                      return _vm.searchCustomers(
                                                        _vm.search.shippers,
                                                        "shippers"
                                                      )
                                                    },
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.searchCustomers(
                                                        _vm.search.shippers,
                                                        "shippers"
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.resetSearchBox(
                                                        "shipperId"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.search.shippers,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.search,
                                                        "shippers",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "search.shippers",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.menuShipper,
                                callback: function ($$v) {
                                  _vm.menuShipper = $$v
                                },
                                expression: "menuShipper",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "dropdown-menu" },
                                [
                                  _c("v-data-table", {
                                    staticClass: "custom-table",
                                    attrs: {
                                      headers: [
                                        { text: "", value: "customerName" },
                                      ],
                                      items: _vm.customersFilterList,
                                      "items-per-page": 4,
                                      page: _vm.tablePage,
                                      "hide-default-header": "",
                                      "checkbox-color": "primary",
                                      "show-select": "",
                                      dense: "",
                                    },
                                    on: {
                                      "update:page": function ($event) {
                                        _vm.tablePage = $event
                                      },
                                      "item-selected": (v) =>
                                        _vm.selectItem(
                                          v,
                                          "shippers",
                                          "customerId"
                                        ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.customerName",
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "custom-table-td",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.customerName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.currentUser.shippers,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentUser,
                                          "shippers",
                                          $$v
                                        )
                                      },
                                      expression: "currentUser.shippers",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "12"
                              : "4",
                          },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                attach: "",
                                "close-on-click": "",
                                "open-on-click": false,
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        !_vm.showSearch["consignees"]
                                          ? _c("v-text-field", {
                                              ref: "consignees",
                                              refInFor: true,
                                              attrs: {
                                                label:
                                                  _vm.$t("label.consignees"),
                                                value: _vm.formatConsignees,
                                                "item-text": "customerName",
                                                "prepend-inner-icon":
                                                  "mdi-account-arrow-right",
                                                "append-icon": user.consignees
                                                  .length
                                                  ? "mdi-close"
                                                  : "",
                                                "hide-details": "",
                                                readonly: "",
                                                dense: "",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  _vm.currentUser.consignees =
                                                    []
                                                },
                                                click: function ($event) {
                                                  _vm.showSearch[
                                                    "consignees"
                                                  ] = true
                                                },
                                              },
                                            })
                                          : _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t(
                                                        "label.consignees"
                                                      ),
                                                    "prepend-inner-icon":
                                                      "mdi-account-arrow-right",
                                                    "append-icon":
                                                      "mdi-magnify",
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: [
                                                      function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                      function ($event) {
                                                        return _vm.handleSearch(
                                                          "consignees"
                                                        )
                                                      },
                                                    ],
                                                    "click:append": function (
                                                      $event
                                                    ) {
                                                      return _vm.searchCustomers(
                                                        _vm.search.consignees,
                                                        "consignees"
                                                      )
                                                    },
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.searchCustomers(
                                                        _vm.search.consignees,
                                                        "consignees"
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.resetSearchBox(
                                                        "shipperId"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.search.consignees,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.search,
                                                        "consignees",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "search.consignees",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.menuConsignee,
                                callback: function ($$v) {
                                  _vm.menuConsignee = $$v
                                },
                                expression: "menuConsignee",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "dropdown-menu" },
                                [
                                  _c("v-data-table", {
                                    staticClass: "custom-table",
                                    attrs: {
                                      headers: [
                                        { text: "", value: "customerName" },
                                      ],
                                      items: _vm.customersFilterList,
                                      "items-per-page": 4,
                                      page: _vm.tablePage,
                                      "hide-default-header": "",
                                      "checkbox-color": "primary",
                                      "show-select": "",
                                      dense: "",
                                    },
                                    on: {
                                      "update:page": function ($event) {
                                        _vm.tablePage = $event
                                      },
                                      "item-selected": (v) =>
                                        _vm.selectItem(
                                          v,
                                          "consignees",
                                          "customerId"
                                        ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.customerName",
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "custom-table-td",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.customerName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.currentUser.consignees,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentUser,
                                          "consignees",
                                          $$v
                                        )
                                      },
                                      expression: "currentUser.consignees",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown
                              ? "12"
                              : "4",
                          },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                attach: "",
                                "close-on-click": "",
                                "open-on-click": false,
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        !_vm.showSearch["foreignAgents"]
                                          ? _c("v-text-field", {
                                              ref: "foreignAgents",
                                              refInFor: true,
                                              attrs: {
                                                label: _vm.$t(
                                                  "label.foreignAgents"
                                                ),
                                                value: _vm.formatForeignAgents,
                                                "item-text": "customerName",
                                                "prepend-inner-icon":
                                                  "mdi-account-arrow-right",
                                                "append-icon": user
                                                  .foreignAgents.length
                                                  ? "mdi-close"
                                                  : "",
                                                "hide-details": "",
                                                readonly: "",
                                                dense: "",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  _vm.currentUser.foreignAgents =
                                                    []
                                                },
                                                click: function ($event) {
                                                  _vm.showSearch[
                                                    "foreignAgents"
                                                  ] = true
                                                },
                                              },
                                            })
                                          : _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "label.foreignAgents"
                                                    ),
                                                    "prepend-inner-icon":
                                                      "mdi-account-arrow-right",
                                                    "append-icon":
                                                      "mdi-magnify",
                                                    "hide-details": "",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    click: [
                                                      function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                      function ($event) {
                                                        return _vm.handleSearch(
                                                          "foreignAgents"
                                                        )
                                                      },
                                                    ],
                                                    "click:append": function (
                                                      $event
                                                    ) {
                                                      return _vm.searchCustomers(
                                                        _vm.search
                                                          .foreignAgents,
                                                        "foreignAgents"
                                                      )
                                                    },
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.searchCustomers(
                                                        _vm.search
                                                          .foreignAgents,
                                                        "foreignAgents"
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.resetSearchBox(
                                                        "shipperId"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.search.foreignAgents,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.search,
                                                        "foreignAgents",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "search.foreignAgents",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.menuForeignAgent,
                                callback: function ($$v) {
                                  _vm.menuForeignAgent = $$v
                                },
                                expression: "menuForeignAgent",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "dropdown-menu" },
                                [
                                  _c("v-data-table", {
                                    staticClass: "custom-table",
                                    attrs: {
                                      headers: [
                                        { text: "", value: "customerName" },
                                      ],
                                      items: _vm.customersFilterList,
                                      "items-per-page": 4,
                                      page: _vm.tablePage,
                                      "hide-default-header": "",
                                      "checkbox-color": "primary",
                                      "show-select": "",
                                      dense: "",
                                    },
                                    on: {
                                      "update:page": function ($event) {
                                        _vm.tablePage = $event
                                      },
                                      "item-selected": (v) =>
                                        _vm.selectItem(
                                          v,
                                          "foreignAgents",
                                          "customerId"
                                        ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.customerName",
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "custom-table-td",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.customerName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.currentUser.foreignAgents,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentUser,
                                          "foreignAgents",
                                          $$v
                                        )
                                      },
                                      expression: "currentUser.foreignAgents",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }