var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ma-0", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "d-inline-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label:
                                                    _vm.$t("label.company"),
                                                  value: _vm.company
                                                    ? `${_vm.company.supplierName} - ${_vm.company.supplierId}`
                                                    : null,
                                                  icon: "mdi-account",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "d-inline-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              staticStyle: {
                                                "min-width": "14rem",
                                              },
                                            },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: "B/L",
                                                  value:
                                                    _vm.pratica.billOfLading,
                                                  icon: "mdi-file-document-outline",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.isMastersped
                                            ? _c(
                                                "v-col",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c("ReadonlyField", {
                                                    attrs: {
                                                      label: "Equipment",
                                                      value:
                                                        _vm.getEquipmentReferences(
                                                          _vm.imballi
                                                        ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "d-inline-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t("label.vessel"),
                                                  value: _vm.pratica.vesselName,
                                                  icon: "mdi-ferry",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "d-inline-flex" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label:
                                                    _vm.$t("label.loadingNode"),
                                                  value: _vm.pratica.pol
                                                    ? _vm.pratica.pol.locode
                                                    : "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("ReadonlyField", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "label.dischargeNode"
                                                  ),
                                                  value: _vm.pratica.pod
                                                    ? _vm.pratica.pod.locode
                                                    : "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-0 mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "background-color": "accent",
                                height: "2rem",
                                grow: "",
                              },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c("v-tab", [
                                _vm._v(_vm._s(_vm.$t("label.tracking"))),
                              ]),
                              _c("v-tab", [
                                _vm._v(_vm._s(_vm.$t("label.packages"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "px-2 pt-2" },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "d-inline-flex" },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c("ReadonlyField", {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "label.departure"
                                                        ),
                                                      value:
                                                        _vm.seaTrackingDeparture,
                                                      icon: "mdi-calendar",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "d-inline-flex" },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c("ReadonlyField", {
                                                    attrs: {
                                                      label:
                                                        _vm.$t("label.arrival"),
                                                      value:
                                                        _vm.seaTrackingArrival,
                                                      icon: "mdi-calendar",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", {
                                        staticClass: "mt-2 mb-0",
                                      }),
                                      _c(
                                        "v-data-table",
                                        {
                                          staticClass: "pa-0",
                                          attrs: {
                                            headers: _vm.trackingHeadersMare,
                                            items: _vm.seaTerminalEvents,
                                            "item-class": _vm.isDepartedFromPol,
                                            "items-per-page": 1000,
                                            "hide-default-footer": "",
                                            dense: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.eventDateTime",
                                              fn: function ({ item }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formatDateShort(
                                                          item.eventDateTime
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.eventCode",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      class:
                                                        _vm.isDepartedFromPol(
                                                          item
                                                        )
                                                          ? _vm.captionBoldClass
                                                          : _vm.captionClass,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.eventCode ==
                                                              "EST"
                                                              ? "ESTIMATED"
                                                              : item.eventCode ==
                                                                "ACT"
                                                              ? "ACTUAL"
                                                              : item.eventCode ==
                                                                "PLN"
                                                              ? "PLANNED"
                                                              : ""
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.eventName",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      class:
                                                        _vm.isDepartedFromPol(
                                                          item
                                                        )
                                                          ? _vm.captionBoldClass
                                                          : _vm.captionClass,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.eventName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.eventType",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      class:
                                                        _vm.isDepartedFromPol(
                                                          item
                                                        )
                                                          ? _vm.captionBoldClass
                                                          : _vm.captionClass,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.eventType
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.eventDescription",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      class:
                                                        _vm.isDepartedFromPol(
                                                          item
                                                        )
                                                          ? _vm.captionBoldClass
                                                          : _vm.captionClass,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.eventDescription
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.transportMode",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center",
                                                    },
                                                    [
                                                      item.transportMode ==
                                                      "TRUCK"
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-truck "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.transportMode ==
                                                        "VESSEL" ||
                                                      item.transportMode == "1"
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-ferry "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.transportMode ==
                                                      "RAIL"
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-train "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "no-data" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    color: "warning",
                                                    size: "20",
                                                  },
                                                },
                                                [_vm._v(" mdi-alert ")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-medium warning--text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "label.noTrackingInfo"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "px-2" },
                                    [
                                      _c(
                                        "v-data-table",
                                        {
                                          staticClass: "pa-0",
                                          attrs: {
                                            headers: _vm.imballiHeaders,
                                            items: _vm.imballi,
                                            search: _vm.imballiSearch,
                                            "items-per-page": 1000,
                                            "hide-default-footer": "",
                                            dense: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "top",
                                              fn: function () {
                                                return [
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "ma-0 pa-0 text-caption",
                                                    attrs: {
                                                      label:
                                                        _vm.$t("label.search"),
                                                      "append-icon":
                                                        "mdi-magnify",
                                                      "single-line": "",
                                                      "hide-details": "",
                                                    },
                                                    model: {
                                                      value: _vm.imballiSearch,
                                                      callback: function ($$v) {
                                                        _vm.imballiSearch = $$v
                                                      },
                                                      expression:
                                                        "imballiSearch",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "item.transportOrderStatus",
                                              fn: function ({ item }) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatTrasportOrderStatus(
                                                            item.transportOrderStatus
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "no-data" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    color: "warning",
                                                    size: "20",
                                                  },
                                                },
                                                [_vm._v(" mdi-alert ")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-medium warning--text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "label.noTrackingInfo"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "ml-3 px-0", attrs: { cols: "auto" } },
            [
              _c("LinkPratica", {
                attrs: { praticaId: _vm.pratica.id },
                on: { "send-link": _vm.loadNewMailTracking },
              }),
              _c("EmailDialog", {
                ref: "emailDialog",
                attrs: { msgTemplate: _vm.emailTemplate, emailAddresses: [] },
                on: { "email-sent": (resp) => _vm.handleDialog(!resp?.sent) },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }