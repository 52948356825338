<template>
  <v-container class="pa-0" fluid>
    <v-row dense>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="imballi"
          :items-per-page="20"
          class="pa-2 pb-0"
          dense
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {imballiService} from '@/services/imballi.service.js'

export default {
  name: 'PraticaAutotrasportoImballi',
  props: ['pratica', 'transportOrder'],
  components: {},
  data() {
    return {
      imballi: [],
      headers: [
        { text: this.$t('label.saleOrder'), value: 'saleOrder' },
        { text: this.$t('label.position'), value: 'saleOrderLine' },
        { text: this.$t('label.quantity'), value: 'quantity' },
        { text: this.$t('label.transportStatus'), value: 'transportOrderStatusLabel' },
        { text: this.$t('label.item'), value: 'itemDescription' },
      ],
    }
  },
  watch: {
    transportOrder() {
      this.getImballi()
    },
  },
  methods: {
    getImballi() {
      if (!this.transportOrder) return
      imballiService
        .getImballiByPraticaTransportOrder(
          this.pratica.documentNbr,
          this.transportOrder.transportOrderNbr,
            this.pratica.tenantId
        )
        .then((resp) => {
          this.imballi = resp
          // this.imballi.forEach((el) => {
          //   el.transportOrderStatus = this.transportOrder.status
          // })
        })
    },
  },
  created() {
    this.getImballi()
  },
}
</script>
