<template>
  <v-container fluid class="pa-0">
    <v-row class="ma-0" dense>
      <v-col class="pa-0">
        <v-row class="ma-0">
          <v-col class="pa-0">
            <v-card>
              <v-data-table
                :headers="headers"
                :items="transportOrders"
                :items-per-page="5"
                hide-default-footer
                class="pa-2"
                dense
              >
                <template v-slot:item="{ item, index }">
                  <tr
                    :class="selectedRow == index ? 'primary lighten-2' : ''"
                    @click="selectTransportOrder(index, item)"
                  >
                    <td>{{ item.transportOrderNbr }}</td>
                    <td>{{ item.carrierId }}</td>
                    <td>{{ item.purchaseOrderNbr }}</td>
                    <td>{{ formatDate(item.pickupDateActual) }}</td>
                    <td>{{ formatDate(item.deliveryDateActual) }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-3">
          <v-col class="pa-0">
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="accent"
                height="2rem"
                grow
              >
                <v-tab>{{ $t('label.details') }}</v-tab>
                <v-tab>{{ $t('label.packages') }}</v-tab>
                <v-tab>{{ $t('label.tracking') }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item class="pa-0">
                  <PraticaAutotrasportoDettagli
                    :pratica="pratica"
                    :transportOrder="selectedTransportOrder"
                  />
                </v-tab-item>
                <v-tab-item class="pa-0">
                  <PraticaAutotrasportoImballi
                    :pratica="pratica"
                    :transportOrder="selectedTransportOrder"
                  />
                </v-tab-item>
                <v-tab-item class="pa-0">
                  <!-- <PraticaAutotrasportoTracking :transportOrder="selectedTransportOrder" /> -->
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="ml-3 px-0">
        <v-btn
          fab
          icon
          height="50"
          width="50"
          class="primary"
          @click="loadNewEmailTransport"
        >
          <v-icon color="white" size="30">mdi-email</v-icon>
        </v-btn>
        <EmailDialog
          ref="emailDialog"
          :msgTemplate="emailTemplate"
          :emailAddresses="[]"
          @email-sent="(resp) => handleDialog(!resp?.sent)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Email from '@/mixins/Email'
import { dateFilter } from '@/filters/dateFilter'
import { praticheService } from '@/services/pratiche.service.js'
import PraticaAutotrasportoImballi from '@/components/elements/pratica/PraticaAutotrasportoImballi.vue'
import PraticaAutotrasportoDettagli from '@/components/elements/pratica/PraticaAutotrasportoDettagli.vue'
// import PraticaAutotrasportoTracking from '@/components/elements/pratica/PraticaAutotrasportoTracking.vue'
import EmailDialog from '@/components/elements/mails/EmailDialog.vue'

export default {
  name: 'PraticaAutotrasporto',
  props: ['pratica'],
  mixins: [Email],
  components: {
    PraticaAutotrasportoImballi,
    PraticaAutotrasportoDettagli,
    // PraticaAutotrasportoTracking,
    EmailDialog,
  },
  data() {
    return {
      tab: 0,
      selectedRow: 0,
      selectedTransportOrder: {},
      transportOrders: [],
      headers: [
        { text: this.$t('label.transport'), value: 'transportOrderNbr' },
        { text: this.$t('label.supplier'), value: 'carrierId' },
        { text: 'Oda', value: 'purchaseOrderNbr' },
        { text: this.$t('label.pickUp'), value: 'pickupDateActual' },
        { text: this.$t('label.delivery'), value: 'deliveryDateActual' },
      ],
    }
  },
  methods: {
    selectTransportOrder(index, item) {
      this.selectedRow = index
      this.selectedTransportOrder = item
    },
    formatDate(value) {
      return dateFilter.formatDateDay(value)
    },
    loadTransportOrders() {
      praticheService
        .getTransportOrdersForPratica(this.pratica.documentNbr, this.pratica.tenantId)
        .then((resp) => {
          if (!resp) return
          this.transportOrders = resp
          if (this.transportOrders.length != 0)
            this.selectedTransportOrder = this.transportOrders[0]
        })
    },
  },
  created() {
    this.loadTransportOrders()
  },
}
</script>
