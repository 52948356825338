<template>
  <v-container fill-height fluid class="d-block pa-0">
    <v-row dense v-if="internalVisibilityAuth && kpiAuth">
      <v-col>
        <KpiCard
          :title="$t('label.airPracticesStatus')"
          :elements="[
            { name: $t('label.toProcess'), value: praticheAereoRedCount },
            { name: $t('label.waitingFeedback'), value: praticheAereoYellowCount },
            { name: $t('label.processed'), value: praticheAereoGreenCount },
          ]"
        />
      </v-col>
      <v-col>
        <KpiCard
          :title="$t('label.seaPracticesStatus')"
          :elements="[
            { name: $t('label.toProcess'), value: praticheMareRedCount },
            { name: $t('label.waitingFeedback'), value: praticheMareYellowCount },
            { name: $t('label.processed'), value: praticheMareGreenCount },
          ]"
        />
      </v-col>
      <v-col>
        <KpiCard
          :title="$t('label.practicesStatus')"
          :elements="[
            { name: $t('label.airPractices'), value: praticheAereoLast12MonthsCount },
            { name: $t('label.seaPractices'), value: praticheMareLast12MonthsCount },
          ]"
        />
      </v-col>
    </v-row>

    <v-row dense v-if="filtersPanel == 0">
      <v-col>
        <v-expansion-panels v-model="filtersPanel">
          <v-expansion-panel>
            <!-- <v-expansion-panel-header class="px-3 pt-1 pb-0"> 
              <span class="text-overline primary--text"> {{ $t('label.filters') }} </span> 
            </v-expansion-panel-header> -->
            <v-expansion-panel-content class="pa-0">
              <FiltersCard ref="filtersCard" @set-filter="filter" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>        
      </v-col>
    </v-row>
    
    <v-row dense v-if="internalVisibilityAuth || !internalVisibilityAuth && (!objIsEmpty(browseFilter) || !objIsEmpty(sortBy))">
      <v-col cols="9" class="d-flex flex-column py-0" v-if="!objIsEmpty(browseFilter) || search">
        <div class="d-flex">
          <v-chip color="secondary" small dark class="mt-1 mr-1">
            <v-avatar left>
              <v-icon size="18">mdi-filter-check</v-icon>
            </v-avatar>
            {{ $t('label.activeFilters') }}
          </v-chip>
          <v-chip color="secondary" small dark class="mt-1 mr-1 px-3" v-if="search">
            <v-avatar left>
              <v-icon size="18">mdi-magnify</v-icon>
            </v-avatar>
            <span class="search-chip"> {{ search }} </span>
          </v-chip>
          <div v-if="!search" style="max-width: max-content">
            <v-chip
              color="secondary"
              outlined
              small
              dark
              class="mr-1 mt-1 px-2"
              v-for="(key, i) in Object.keys(visualFilter)"
              :key="i"
            >
              <div v-html="formatFilterChip(key, visualFilter[key])"></div>
            </v-chip>
          </div>
          
        </div>
      </v-col>
      <v-spacer />
      <v-col cols="3" class="d-flex justify-end align-start px-0">
          <v-chip
            v-for="(key, i) in Object.keys(sortBy)"
            :key="i"
            @click="resetSortBy"
            color="secondary"
            class="mr-1"
            outlined
            small
            dark
          >
            <div v-html="formatSortByChip(sortBy[key])" />
          </v-chip>
        <FiltersSection
          v-if="internalVisibilityAuth"
          ref="filtersSection"
          @sort-pratiche="sort"
          @set-filter="filter"
          @reset-filter="resetAllFilters"
          @open-filters="handleFiltersPanel"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="[]"
          :items="browseResult"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="cards-table"
        >
          <template v-slot:item="{ item, index }">
            <PraticaRow
              :pratica="item"
              :currentSelection="rowsSelection"
              :showSelection="showSelectableTable"
              @select-row="saveSelectedRow(index)"
              @add-to-selection="selectItem(item)"
              :class="selectedRow == index ? 'selected-row' : ''"
            />
          </template>
          <template v-slot:top>
            <v-row dense class="ma-0">
              <v-col class="d-flex align-center pa-0">
                <span class="font-weight-bold ml-1">{{ browseCount }}</span>
                <span class="text-caption ml-1">
                  {{ $t('label.resultsFound') }}
                </span>
              </v-col>
              <v-col class="pa-0">
                <TableNav
                  ref="tableNav"
                  :browse-count="browseCount"
                  :browse-limit="browseLimit"
                  :browse-offset="browseOffset"
                  @offset-changed="offsetChanged"
                  @get-current-page="setCurrent"
                />
              </v-col>
              <v-col class="d-flex align-center pa-0">
                <v-spacer />
                <div
                  class="d-flex align-center pa-0"
                  v-if="internalVisibilityAuth"
                >
                  <v-select
                    v-model="currentAction"
                    :items="dashboardActions"
                    :menu-props="{
                      offsetY: true,
                      contentClass: 'mx-1',
                    }"
                    class="actions-select text-caption mx-1"
                    style="max-width: 10rem;"
                    hide-details
                    clearable
                    outlined
                    x-small
                    dense
                  />
                  <v-btn
                    :disabled="!rowsSelection.length"
                    @click="doAction(currentAction)"
                    class="px-0"
                    color="primary"
                    icon
                  >
                    <v-icon size="30">
                      mdi-play-circle
                    </v-icon>
                    <!-- <span v-if="currentAction == $t('upper.sendPreAlert')"> {{ $t('label.send') }} </span>
                    <span v-else>{{ $t('label.execute') }}</span>
                    <v-icon v-if="currentAction == $t('upper.sendPreAlert')" class="ml-1" x-small> mdi-email </v-icon> -->
                  </v-btn>
                </div>
                <FiltersSection
                  v-if="internalVisibilityAuth == false"
                  ref="filtersSection"
                  @sort-pratiche="sort"
                  @set-filter="filter"
                  @reset-filter="resetAllFilters"
                  @open-filters="handleFiltersPanel"
                />
              </v-col>
            </v-row>
            <v-progress-linear v-if="loading" indeterminate class="mt-1" />
          </template>
          <template v-slot:footer>
            <v-progress-linear
              v-if="loading && browseResult.length"
              indeterminate
            />
            <v-row dense class="ma-0">
              <v-col class="pa-0">
                <TableNav
                  v-if="browseResult.length"
                  ref="tableNavFooter"
                  :browse-count="browseCount"
                  :browse-limit="browseLimit"
                  :browse-offset="browseOffset"
                  @offset-changed="offsetChanged"
                  @get-current-page="setCurrent"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <EmailDialog
      ref="emailDialog"
      :msgTemplate="emailTemplate"
      :emailAddresses="[]"
      @email-sent="handleNewMail"
    /> -->
    <ForeignAgentEmail
      ref="foreignAgentEmail"
      :rowsSelection="rowsSelection"
      :pratiche="browseResult"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { praticheService } from '@/services/pratiche.service.js'
import KpiCard from '@/components/elements/KpiCard.vue'
import PraticaRow from '@/components/elements/pratica/PraticaRow.vue'
import FiltersSection from '@/components/elements/FiltersSection.vue'
import TableNav from '@/components/elements/TableNav.vue'
import FiltersCard from '@/components/elements/FiltersCard.vue'
import ForeignAgentEmail from '@/components/elements/pratica/foreign-agent/ForeignAgentEmail.vue'

export default {
  name: 'DashboardPage',
  props: ['reload'],
  emits: ['clear-search'],
  components: {
    KpiCard,
    PraticaRow,
    TableNav,
    FiltersSection,
    ForeignAgentEmail,
    FiltersCard
  },
  data() {
    return {
      filtersPanel: null,
      selectedRow: 0,
      rowsSelection: [],
      loading: false,
      filterDialog: false,
      showImballaggi: false,
      browseResult: [],
      browseCount: 0,
      itemsPerPage: 50,

      browseFilter: {},
      visualFilter: {},
      browseLimit: 50,
      browseOffset: 0,
      currentPage: 1,
      sortBy: [],

      praticheAereoRedCount: 0,
      praticheAereoYellowCount: 0,
      praticheAereoGreenCount: 0,
      praticheMareRedCount: 0,
      praticheMareYellowCount: 0,
      praticheMareGreenCount: 0,

      praticheAereoLast12MonthsCount: 0,
      praticheMareLast12MonthsCount: 0,

      seaSaleChannel: '20',
      airSaleChannel: '40',
      statusToProcess: 'RED',
      statusWaiting: 'YELLOW',
      statusProcessed: 'GREEN',

      currentAction: null,
      sendPrealertAction: this.$t('upper.sendPreAlert'),
      showSelectableTable: false,

      lastSearchType: undefined
    }
  },
  computed: {
    ...mapState('searchbar', ['search', 'searchField']),
    ...mapState('account', ['user', 'internalVisibilityAuth', 'userAuthorizations', 'filtersAreOpen']),
    userCompleteName() {
      let name =
        this.user.firstName.charAt(0).toUpperCase() +
        this.user.firstName.slice(1)
      let surname =
        this.user.lastName.charAt(0).toUpperCase() + this.user.lastName.slice(1)
      return name.concat(' ').concat(surname)
    },
    dashboardActions() {
      return [this.$t('upper.sendPreAlert')]
    },
    kpiAuth(){
      return this.userAuthorizations.includes('AUTH_KPI');
    }
  },
  watch: {
    search(value) {
      clearTimeout(this.searchTimeoutId)
        this.searchTimeoutId = setTimeout(() => {
          this.fastSearch(value)
        }, 400)
    },
    searchField(newVal, oldVal) {
      if (newVal != oldVal && this.search && this.search.length)
        this.fastSearch(this.search)
    },
    browseOffset() {
      this.browse()
    },
    currentAction(action) {
      this.rowsSelection = []
      this.showSelectableTable = action ? true : false
      switch (action) {
        case this.sendPrealertAction:
          this.ifSameAwb()
          break
      }
    },
    filtersPanel(panel){
      this.$nextTick(() => {
        if (panel == 0) this.$refs.filtersCard.restoreFilterMemory()
      })
    },
    reload: {
      handler(reload) {
        this.loadBrowseMemory()
        if (!reload) return 
        if (this.currentPage == 1) this.browse()
      },
      immediate: true
    },
    async kpiAuth(isAuthorized){
      console.log(isAuthorized)
      if (!isAuthorized) return
      this.praticheAereoRedCount = await this.getPraticheCountByStatusAndTypeInInterval(this.statusToProcess, this.airSaleChannel)
      this.praticheAereoYellowCount = await this.getPraticheCountByStatusAndTypeInInterval(this.statusWaiting, this.airSaleChannel)
      this.praticheAereoGreenCount = await this.getPraticheCountByStatusAndTypeInInterval(this.statusProcessed, this.airSaleChannel)
      this.praticheMareRedCount = await this.getPraticheCountByStatusAndTypeInInterval(this.statusToProcess, this.seaSaleChannel)
      this.praticheMareYellowCount = await this.getPraticheCountByStatusAndTypeInInterval(this.statusWaiting, this.seaSaleChannel)
      this.praticheMareGreenCount = await this.getPraticheCountByStatusAndTypeInInterval(this.statusProcessed, this.seaSaleChannel)
      this.praticheAereoLast12MonthsCount = await this.getPraticheCountByTypeInInterval(this.airSaleChannel)
      this.praticheMareLast12MonthsCount = await this.getPraticheCountByTypeInInterval(this.seaSaleChannel)
    },
    filtersAreOpen(areOpen){
      if (areOpen) this.filtersPanel = 0
    }
  },
  methods: {
    async browse() {
      // if (this.search && this.search.length) return
      this.$emit('clear-search')
      this.lastSearchType = 'browse'
      this.loading = true
      praticheService
        .browse(this.browseOffset, this.browseLimit, this.browseFilter, this.sortBy)
        .then((resp) => {
          if (!resp || this.lastSearchType !== 'browse') return this.loading = false
          this.browseResult = resp.resultList
          this.browseCount = resp.totalCount
          this.rowsSelection = []
          this.loading = false
          this.saveBrowseMemory()
          if (this.browseResult.length) this.gotoSelectedRow()
        })
    },
    getPraticheCountByStatusAndTypeInInterval(status, saleChannel) {
      return praticheService.getPraticheByStatusAndSaleChannel(
        status,
        saleChannel,
      )
    },
    getPraticheCountByTypeInInterval(saleChannel) {
      return praticheService.getCountByTypeInInterval(saleChannel)
    },
    resetOffsetAndBrowse() {
      this.resetOffset()
      this.browse()
    },
    resetOffset() {
      this.browseOffset = 0
    },
    resetAllFilters(){
      this.sortBy = []
      if (this.$refs.filtersCard) this.$refs.filtersCard.restoreFilterMemory()
    },
    offsetChanged(newOffset, page) {
      this.browseOffset = newOffset
      this.currentPage = page
    },
    sort(sortBy) {
      if (this._.isEqual(this.sortBy[0], sortBy)) return
      this.sortBy = []
      if (sortBy) this.sortBy.push(sortBy)
      this.resetOffsetAndBrowse()
    },
    filter(browseFilter, visualFilter) {
      this.browseFilter = browseFilter
      this.visualFilter = visualFilter
      this.currentPage != 1 ? this.resetOffset() : this.resetOffsetAndBrowse()
    },
    resetSortBy() {
      this.sortBy = []
      this.browse()
    },
    handleFiltersPanel(){
      if (this.filtersPanel == 0) this.filtersPanel = null
      else this.filtersPanel = 0
    },
    fastSearch(search) {
      if (!search) {
        this.currentAction = null
        return this.resetOffsetAndBrowse()
      }
      if (search.length > 2) {
        this.lastSearchType = 'fast'
        praticheService
          .fastSearchPratiche(this.searchField, search)
          .then((resp) => {
            if (!resp) return (this.loading = false)
            if (this.search != search || this.lastSearchType !== 'fast') {
              return
            } 
            this.browseResult = resp.resultList
            this.browseCount = resp.totalCount
            this.ifSameAwb()
          })
          .catch((error) => {
            this.browseResult = []
            this.browseCount = 0
            this.setSnackbarMessage({ text: error, color: 'error' })
          })
      }
    },
    formatFilterChip(key, value) {
      let chipText = key.replace(/([a-z])([A-Z])/g, '$1 $2')
      chipText = chipText.includes('document') || chipText.includes('sale') ? chipText.substring(chipText.indexOf(' ') + 1) : chipText.replace(/ .*/, '')
      chipText = chipText.charAt(0).toUpperCase() + chipText.slice(1)

      let valueToArray = value.toString().split(',')
      if (valueToArray.length > 1)
        return (chipText += `<b> +${valueToArray.length}</b>`)
      else return (chipText += `<b> ${value}</b>`)
    },
    formatSortByChip(val) {
      let column = val.column.charAt(0).toUpperCase() + val.column.slice(1)
      let direction = val.direction
      return `<b>${column} - ${direction}</b>`
    },
    objIsEmpty(object) {
      return Object.keys(object).length == 0
    },
    setCurrent() {
      this.$refs.tableNav.page = this.currentPage
      if (this.$refs.tableNavFooter)
        this.$refs.tableNavFooter.page = this.currentPage
    },
    gotoSelectedRow() {
      const options = {
        duration: 500,
        offset: 410,
      }
      this.$nextTick(() =>
        setTimeout(() => {
          this.$vuetify.goTo('.selected-row', options)
          this.saveSelectedRow(0)
        }, 500),
      )
    },
    selectItem(item) {
      if (!this.rowsSelection.includes(item)) this.rowsSelection.push(item)
      else {
        let index = this.rowsSelection.indexOf(item)
        this.rowsSelection.splice(index, 1)
      }
    },
    // Memory handling
    loadSelectedRow() {
      this.selectedRow = this.getCurrentRow(this.$options.name)
      if (!this.selectedRow) this.saveSelectedRow(0)
    },
    saveSelectedRow(index) {
      this.selectedRow = index
      this.setCurrentRow(this.$options.name, this.selectedRow)
    },
    removeSelectedRow() {
      this.removeCurrentRow(this.$options.name)
    },
    loadBrowseMemory() {
      let memory = this.getBrowseMemory(this.$options.name)
      if (!memory) return
      this.browseFilter = memory.browseFilter
      this.visualFilter = memory.visualFilter
      this.browseLimit = memory.browseLimit
      this.browseOffset = memory.browseOffset
      this.sortBy = memory.sortBy
      this.currentPage = memory.currentPage
    },
    saveBrowseMemory() {
      let memory = {
        browseFilter: this.browseFilter,
        visualFilter: this.visualFilter,
        browseLimit: this.browseLimit,
        browseOffset: this.browseOffset,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
      }
      this.setBrowseMemory(this.$options.name, memory)
    },
    // Actions
    ifSameAwb() {
      this.rowsSelection = []
      if (!this.checkIfHaveSameAwb()) return
      this.browseResult.forEach((element) => {
        this.rowsSelection.push(element)
      })
      this.currentAction = this.sendPrealertAction
    },
    checkIfHaveSameAwb() {
      if (!this.search || !this.search.length) return
      let sameAwb = true
      this.browseResult.forEach((row) => {
        if (row.awb != this.search) sameAwb = false
      })
      return sameAwb
    },
    doAction(action) {
      switch (action) {
        case this.$t('upper.sendPreAlert'):
          this.$refs.foreignAgentEmail.checkForeignAgent()
          break
      }
    },
  },
  created() {
    if (this.search) this.fastSearch(this.search)
    if (this.filtersAreOpen) this.filtersPanel = 0
    this.loadSelectedRow()
  },
}
</script>

<style scoped>
.nohover tr:hover {
  background-color: transparent !important;
}
.cards-table {
  background-color: var(--v-primary-lighten2);
}
.actions-select::v-deep .v-input__append-inner {
  margin: 0 !important;
  padding: 0 !important;
}
.actions-select::v-deep .v-input__slot {
  min-height: 0 !important;
  max-height: fit-content !important;
  padding-right: 0 !important;
}
.actions-select::v-deep .v-select__selection--comma {
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 0.1rem !important;
}
.actions-select::v-deep input {
  padding: 0 !important;
}
.actions-select::v-deep .v-select__selections {
  padding: 0 !important;
}
.actions-select::v-deep .v-input__append-inner {
  align-self: center !important;
}
.actions-select::v-deep .v-input__icon--clear {
  min-width: 0 !important;
  width: fit-content !important;
}
.actions-select::v-deep .v-input__icon--clear .v-icon {
  font-size: 15px !important;
}
.v-chip {
  max-height: 1.3rem;
}
.v-expansion-panel::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-expansion-panel::v-deep .v-expansion-panel-header {
  min-height: 0 !important;
}
.search-chip {
  max-width: 5.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
