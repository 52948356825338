<template>
  <v-carousel
    v-model="page"
    hide-delimiters
    :show-arrows="false"
    width="100%"
    height="100%"
    touchless
    class="pa-1"
  >
    <component
      :is="pageToShow.component"
      :ref="pageToShow.ref"
      :key="pageToShow.ref"
      :pratica="pratica"
      :imballi="imballi"
      @select-request="selectElectronicFlow"
      @exit-request="exitRequest"
    />
  </v-carousel>
</template>

<script>
import Booking from '@/mixins/Booking.js'
import { dateFilter } from '@/filters/dateFilter'
import { imballiService } from '@/services/imballi.service.js'
import { suppliersService } from '@/services/suppliers.service.js'
import ElectronicFlowsPage from '@/components/views/ElectronicFlowsPage.vue'
import BookingStepper from '@/components/elements/booking/BookingStepper.vue'
import ShippingInstructionStepper from '@/components/elements/shipping-instruction/ShippingInstructionStepper.vue'

export default {
  name: 'BookingTrial',
  props: ['pratica'],
  mixins: [Booking],
  components: { ElectronicFlowsPage, BookingStepper, ShippingInstructionStepper },
  data() {
    return {
      page: null,
      imballi: [],
      pages: [
        {
          component: 'ElectronicFlowsPage',
          ref: 'electronicFlowsPage',
        },
        {
          component: 'BookingStepper',
          ref: 'bookingStepper',
        },
        {
          component: 'ShippingInstructionStepper',
          ref: 'shippingInstructionStepper',
        }
      ],
    }
  },
  computed: {
    pageToShow() {
      if (this.page == null) return {}
      return this.pages[this.page]
    },
  },
  methods: {
    init() {
      this.page = 0
      this.getCompany()
      this.loadImballiForPratica()
    },
    getCompany() {
      suppliersService
        .getSupplierBySupplierId(this.pratica.mainTransportCompany, this.pratica.tenantId)
        .then((resp) => {
          if (!resp) return
          let company
          if (resp.supplierName.includes('MAERSK')) company = 'MAERSK'
          if (resp.supplierName.includes('CMA') && resp.supplierName.includes('CGM')) company = 'CMACGM'
          if (resp.supplierName.includes('YANG') && resp.supplierName.includes('MING')) company = 'YANGMING'
          this.setCompany(company)
        })
    },
    loadImballiForPratica() {
      imballiService
        .getImballiByDocumentNbr(this.pratica.documentNbr, this.pratica.tenantId)
        .then((resp) => (this.imballi = this.clearUnusedImballi(resp)))
    },
    clearUnusedImballi(imballi) {
      return imballi.filter((imballo) => imballo.itemDescription != 'NON USARE')
    },
    selectElectronicFlow(electronicFlow){
      this.setElectronicFlow(electronicFlow)
      switch (electronicFlow.objectType) {
        case 'BK':
          this.page = 1
          break;
        case 'SI':
          this.page = 2
          break;
      }
    },
    exitRequest(){
      this.page = 0
      this.resetStore()
    },
    resetStore(){
      this.resetBookingRequest()
      this.resetElectronicFlow()
      this.resetShippingInstruction()
    },
    // Utils
    formatDateDay(value) {
      return dateFilter.formatDateDay(value)
    },
  },
  created() {
    this.resetStore()
    this.init()
  }
}
</script>

<style scoped>
.v-list-item {
  min-height: 0 !important;
}
.v-expansion-panel::v-deep .v-expansion-panel-header,
.v-expansion-panel::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-expansion-panel::v-deep .v-expansion-panel-content {
  width: 100%;
}
/*.row {
  border: solid 1px red;
}
.col {
  border: solid 1px orange;
}*/
</style>
