<template>
  <v-container class="d-flex flex-column">
    <v-row class="d-flex flex-row align-center">
      <v-col cols="9">
        <v-file-input
            v-model="selectedFile"
            label="Upload file"
            accept=".xlsx"
            clearable
            @change="onFileSelected"
        ></v-file-input>
      </v-col>
    </v-row>
    <span v-if="totalRows">{{ `Total Rows: ${totalRows}` }}</span>

    <v-card v-if="errorRows.size">
      <v-card-title>
        <v-icon left color="error">mdi-alert-circle</v-icon>
        {{ `Failed WBS: ${errorRows.size}` }}
      </v-card-title>

      <v-card-text>
        <v-list>
          <v-list-item
              v-for="([rowId, error], index) in Array.from(errorRows)"
              :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>
                <strong>{{ rowId }}:</strong> {{ error }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {roadShipmentsService} from "../../services/roadShipments.service";

export default {
  name: "RoadImportPage",
  data() {
    return {
      selectedFile: null,
      totalRows: null,
      errorRows: new Map()
    }
  },
  methods: {
    onFileSelected() {
      if (this.selectedFile != null) {
        this.totalRows = null
        this.errorRows =  new Map()

        const formData = new FormData();
        formData.append("file", this.selectedFile);
        try {
          roadShipmentsService.importRoadShipments(formData)
              .then((resp) => {
                this.totalRows = resp.totalRows
                for (const [rowId, error] of Object.entries(resp.errorRows)) {
                  this.errorRows.set(rowId, error);
                }
              });

        } catch (error) {
          console.error("Failed to upload file", error);
        }
      }
    },
  }
}
</script>

<style scoped>

</style>