<template>
  <v-container fluid class="pa-0">
    <v-row class="ma-0" dense>
      <v-col class="pa-0">
        <v-row class="ma-0">
          <v-col class="pa-0">
            <v-card class="pa-2">
              <v-row dense>
                <v-col class="d-inline-flex">
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.airCompany')"
                      :value="`${pratica.airwayCompany ? `${pratica.airwayCompany.description} - ${pratica.airwayCompany.threeDigitCode}` : ''}`"
                      :icon="'mdi-airplane'"
                    />
                  </v-col>
                </v-col>
                <v-col class="d-inline-flex">
                  <v-col class="pa-0 pl-7">
                    <ReadonlyField
                      :label="$t('label.loadingNode')"
                      :value="pratica.pol ? pratica.pol.locode : ''"
                    />
                  </v-col>
                  <v-col class="pa-0 pl-7">
                    <ReadonlyField
                      :label="$t('label.dischargeNode')"
                      :value="pratica.pod ? pratica.pod.locode : ''"
                    />
                  </v-col>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="d-inline-flex">
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.awb')"
                      :value="pratica.awb"
                      :icon="'mdi-file-document-outline'"
                    />
                  </v-col>
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.status')"
                      :value="trackResponse.status"
                      :icon="'mdi-list-status'"
                    />
                  </v-col>
                </v-col>
                <v-col class="d-inline-flex">
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.etd')"
                      :value="etdDate"
                      :icon="'mdi-calendar'"
                    />
                  </v-col>
                  <v-col class="pa-0">
                    <ReadonlyField
                      :label="$t('label.eta')"
                      :value="etaDate"
                      :icon="'mdi-calendar'"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-3">
          <v-col class="pa-0">
            <v-card>
              <v-card-title class="py-1 px-3">
                <span class="text-overline primary--text">
                  {{ $t('upper.airportEvents') }}
                </span>
              </v-card-title>
              <v-divider class="my-0 mx-2" />
              <v-data-table
                :headers="airportEventsHeaders"
                :items="airportEvents"
                :items-per-page="1000"
                hide-default-footer
                class="px-2 py-0"
                dense
              >
                <!-- :no-data-text="$t('label.noTrackingInfo')" -->
                <template slot="no-data">
                  <v-icon color="warning" class="mr-1" size="20">
                    mdi-alert
                  </v-icon>
                  <span class="font-weight-medium warning--text">
                    {{ $t('label.noTrackingInfo') }}
                  </span>
                </template>
                <template v-slot:item.planned="{ item }">
                  {{ formatDate(item.planned) }}
                </template>
                <template v-slot:item.actual="{ item }">
                  {{ formatDate(item.actual) }}
                </template>
                <template v-slot:item.isReplanned="{ item }">
                  <v-icon v-if="item.isReplanned" color="primary">
                    mdi-check-bold
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-3">
          <v-col class="pa-0">
            <v-card>
              <v-card-title class="py-1 px-3">
                <span class="text-overline primary--text">
                  {{ $t('upper.routingEvents') }}
                </span>
              </v-card-title>
              <v-divider class="my-0 mx-2" />
              <v-data-table
                :headers="trackingHeadersAereo"
                :items="routingEvents"
                :items-per-page="1000"
                hide-default-footer
                sort-by="departureActualTime"
                :sort-desc="false"
                class="px-2 py-0"
                dense
              >
                <template slot="no-data">
                  <v-icon color="warning" class="mr-1" size="20">
                    mdi-alert
                  </v-icon>
                  <span class="font-weight-medium warning--text">
                    {{ $t('label.noTrackingInfo') }}
                  </span>
                </template>
                <template v-slot:item.departureScheduleTime="{ item }">
                  {{ formatDate(item.departureScheduleTime) }}
                </template>
                <template v-slot:item.departureActualTime="{ item }">
                  {{ formatDate(item.departureActualTime) }}
                </template>
                <template v-slot:item.arrivalScheduleTime="{ item }">
                  {{ formatDate(item.arrivalScheduleTime) }}
                </template>
                <template v-slot:item.arrivalActualTime="{ item }">
                  {{ formatDate(item.arrivalActualTime) }}
                </template>
                <template v-slot:item.isReplanned="{ item }">
                  <v-icon v-if="item.isReplanned" color="primary">
                    mdi-check-bold
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-3">
          <v-col class="pa-0">
            <v-card>
              <v-card-title class="py-1 px-3">
                <span class="text-overline primary--text">
                  {{ $t('upper.alertEvents') }}
                </span>
              </v-card-title>
              <v-divider class="my-0 mx-2" />
              <v-data-table
                :headers="alertEventsHeaders"
                :items="alertEvents"
                :items-per-page="1000"
                hide-default-footer
                calculate-widths
                class="px-2 py-0"
                dense
              >
                <template slot="no-data">
                  <v-icon color="warning" class="mr-1" size="20">
                    mdi-alert
                  </v-icon>
                  <span class="font-weight-medium warning--text">
                    {{ $t('label.noAlertEvents') }}
                  </span>
                </template>
                <template v-slot:item.programmato="{ item }">
                  {{ formatDate(item.programmato) }}
                </template>
                <template v-slot:item.effettivo="{ item }">
                  {{ formatDate(item.effettivo) }}
                </template>
                <template v-slot:item.isReplanned="{ item }">
                  <v-icon v-if="item.isReplanned" color="primary">
                    mdi-check-bold
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="d-flex flex-column ml-3 px-0">
        <LinkPratica :praticaId="pratica.id" @send-link="loadNewMailTracking" />
        <EmailDialog
          ref="emailDialog"
          :msgTemplate="emailTemplate"
          :emailAddresses="[]"
          @email-sent="(resp) => handleDialog(!resp?.sent)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Email from '@/mixins/Email'
import Tracking from "@/mixins/Tracking";
import { dateFilter } from '@/filters/dateFilter'
import { praticheService } from '@/services/pratiche.service.js'
import ReadonlyField from '@/components/elements/ReadonlyField.vue'
import LinkPratica from '@/components/elements/pratica/LinkPratica.vue'
import EmailDialog from '@/components/elements/mails/EmailDialog.vue'

export default {
  name: 'PraticaTrasportoPrincipaleAereo',
  props: ['pratica'],
  mixins: [Email, Tracking],
  components: { ReadonlyField, LinkPratica, EmailDialog },
  data() {
    return {
      tab: 0,

      trackResponse: {},
      alertEvents: [],
      airportEvents: [],
      routingEvents: [],
    }
  },
  computed: {
    etdDate() {
      return dateFilter.formatDate(this.trackResponse.etdPlanned)
    },
    etaDate() {
      return dateFilter.formatDate(this.trackResponse.etaPlanned)
    },
  },
  methods: {
    trackPratica() {
      praticheService.trackPraticaAereo(this.pratica.id).then((resp) => {
        if (!resp) return
        this.trackResponse = resp
        this.routingEvents = resp.routing
        let navettamento = resp.lastRoadFeederServiceEvent
        if (navettamento) navettamento.phase = 'ORIGIN'
        this.alertEvents.push(navettamento)
        if (resp.lastOriginEvent) {
          resp.lastOriginEvent.travelPhaseEnum = 'ORIGIN'
          this.airportEvents.push(resp.lastOriginEvent)
        }
        if (resp.lastDestinationEvent) {
          resp.lastDestinationEvent.travelPhaseEnum = 'DESTINATION'
          this.airportEvents.push(resp.lastDestinationEvent)
        }
      })
    },
    formatDate(value) {
      return dateFilter.formatDate(value)
    },
    formatDateDay(value) {
      return dateFilter.formatDateDay(value)
    },
  },
  created() {
    this.trackPratica()
  },
}
</script>

<style scoped>
.v-simple-checkbox .theme--light.v-icon {
  color: #005eb8 !important;
}

.v-simple-checkbox .v-input--selection-controls__input {
  margin: 0 !important;
}
</style>
