import { authHeader } from '@/helpers/auth-header'
import { commons } from './commons'

export const suppliersService = {
  getAllSuppliers,
  getSupplier,
  getSupplierBySupplierId,
  browseInLike
}

const resourceUrl = process.env.VUE_APP_GSCV_ENGINE_URL + `/supplier`

function getAllSuppliers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl, requestOptions).then(commons.handleJsonResponse)
}

function getSupplier(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/${id}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function getSupplierBySupplierId(supplierId, tenantId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(resourceUrl + `/supplier-id/${supplierId}/${tenantId}`, requestOptions).then(
    commons.handleJsonResponse,
  )
}

function browseInLike(browseRequest) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify({
      offset: browseRequest.offset,
      limit: browseRequest.limit,
      filter: browseRequest.filter,
      sortingList: browseRequest.sortingList,
    }),
  }
  return fetch(resourceUrl + `/search-used`, requestOptions).then(commons.handleJsonResponse)
}
